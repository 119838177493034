.home {
  padding-bottom: rem(20);
  &__buttonBox{
    margin-top: rem(30);

  }
  &__title{
    width: 100%;
    color:$gray;
    text-align: center;
    font-weight: 700;
    margin-top: rem(20);
  }
}
