$darkBlueText: #223354;

$greenText: #44d600;
$borderGreen: #3bb900;
$greenShadow: #44d60080;
$hoverBtnGreen: #36ab00;
$lightGreen: #ecfbe6;

$redMozzo: #f0532d;
$orangeTitle: #ff8000;

$bgOrange: #eed39b;
$lightGray: #a0a0a0;

$colorRed: red;
$primaryOrange: #f49200;
$primaryGray: #606060;
$midOrange: #ffa31a;
$warningColor: #ffaf39;
$lightOrange: #fff6e8;
$tintYellow: #efcb9126;

$primaryBlue: #1a75ff;
$secondaryBlue: #0ab6ff;

$primaryGreen: #44d700;
$primaryRed: #ff1a43;
$primaryButton: #f0532d;
$primaryButtonHover: #f7941e;
$lightRed: #ffe8ec;
$secondaryRed: #f4002c;

$normalWhite: #fff;

$normalBlack: #000;

$softGray: #8c95a7;
$softGray2: #626e86;
$softGray3: #f6f8fb;
$gray: #6b6b6b;
$borderGray: #223354;
$inputGray: #cccedd;
$boldGrayText: #797979;
$hoverBtnGray: #585d7f;
$trendingGrayColor: #ecfbe6;
$inputHelperText: #666666;

$f-text: 'Poppins';

$bp-mobile: 599px;
// Medium screen / tablet
$bp-tablet: 768px;
// Large screen / tablet portrait
$bp-desktop: 1024px;
// Large screen / desktop
$bp-desktop-medium: 1200px;
// Extra large screen / wide desktop
$bp-desktop-large: 1440px;
// Extra extra large screen / extra wide desktop
$bp-desktop-extra-large: 1680px;

// Breakpoints in the form (name: length)
$breakpoints: (
  mobile: $bp-mobile,
  tablet: $bp-tablet,
  desktop: $bp-desktop,
  desktop-medium: $bp-desktop-medium,
  desktop-large: $bp-desktop-large,
  desktop-extra-large: $bp-desktop-extra-large
) !default;
$f-base: 16;

//Buttons
$buttonRadius: 20px;
