.footer{
    background-color: $redMozzo;
    padding: rem(15);
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    color: white;
    font-size: 25px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 1 !important;
    display: flex;
    align-items: center;
    font-size: rem(15);
    justify-content: space-between;
    &__total{
        width: rem(130);
        text-align: right;
        border-left: 1px solid $bgOrange;
    }
    a{
        color: white;
    }
}
