.checkoutPage{
    padding: 0px !important;


    &__mainBox{
        padding: rem(0) rem(20) rem(0) rem(20);
    }
    &__resumeBox{
        margin-top:rem(20);
        margin-bottom:rem(20);
        padding: rem(20) !important;
        background-color: $orangeTitle;
        border-radius: rem(20);
        color:white;
        &__subtotal{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        &__iva{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        &__fee{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        &__line{
            width: 100%;
            border-bottom: 1px solid white;
            margin-top: rem(15);
            margin-bottom: rem(15);
        }
        &__total{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }    
    &__box{
        position: relative;
        //background-color: $bgOrange;
        padding: rem(0) rem(20) rem(0) rem(20) !important;
        display: block !important;
        align-items: center;       
      }
    &__content{
        width: 100%;
        margin-left: rem(10);
        color:$primaryGray;
        font-weight: bold;
        font-size: rem(12);
        &__description{
            color:$primaryGray;
            font-size: rem(10);
            font-weight: 200;
        }
        &__complements{
            color:$primaryGray;
            font-size: rem(10);
            font-weight: 200;
        }
        &__quantity{
            color:$primaryGray;
            font-size: rem(10);
            font-weight: 200;
        }
        &__price{
            color:$colorRed;
            font-size: rem(10);
            font-weight: 200;
        }
    }
    &__continue{
        text-align: center;
        margin-bottom: rem(12);
    }
    
    &__checkout{
        text-align: center;
        margin-bottom: rem(12);
    }
    
    &__actions{
        padding: rem(10);
        text-align: center;
        button{
            padding: rem(5) !important;
            margin-bottom: rem(10);
        }
    }
}