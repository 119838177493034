.singleOrder {
  background-color: white;
  padding: rem(6) rem(0) rem(6) rem(0);
  margin-top: rem(10);
  margin-bottom: rem(10);
  border-radius: rem(10);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    color: $primaryGray;
    font-size: rem(20);
  }

  &__box {
    margin-top: rem(20);
  }

  &__price {
    color: $primaryGray;
    width: auto;
    font-size: rem(13);
  }

  &__createdAt {
    color: $primaryGray;
    width: auto;
    font-size: rem(13);
  }
  &__rating {
    text-align: center;
    width: 218px;
    button {
      margin-bottom: rem(10);
      font-size: 10px !important;
      padding: rem(2) !important;
    }
  }
  &__content {
    text-align: left;
    width: 100%;
    margin-left: rem(10);
    color: $primaryGray;
    font-weight: bold;
    &__description {
      color: $primaryGray;
      // font-size: rem(8);
      font-size: rem(10);
    }
  }
  img {
    width: rem(50);
  }
}
