.paymentPage{
    padding: 0px !important;

    &__newCard{
        display: flex;
        justify-content: space-between;    
        padding-left: rem(20) !important;
        padding-right: rem(20) !important;
        button{
            padding: rem(5) !important;
        }    
    }
    &__selectCard{
        text-align: center;
        button{
            margin-top: rem(10) !important;
        }  
    }

    &__mainBox{
        padding: rem(0) rem(20) rem(0) rem(20);
    }
    &__resumeBox{
        margin-bottom:rem(20);
        padding: rem(20) !important;
        border-radius: rem(20);
        &__line{
            width: 100%;
            border-bottom: 1px solid $primaryGray;
            margin-top: rem(15);
            margin-bottom: rem(15);
        }
        &__name{
            width: 100%;           
        }
        &__number{
            width: 100%;           
        }
        &__date{
            &__content{
                width: 48%;
            }
            width: 100%;   
            display: flex;
            justify-content: space-between;        
        }
        &__total{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }    
    &__box{
        position: relative;
        //background-color: $bgOrange;
        padding: rem(0) rem(20) rem(0) rem(20) !important;
        display: block !important;
        align-items: center;       
        margin-bottom: rem(20);
      }
   
    &__continue{
        text-align: center;
        margin-bottom: rem(12);
    }
    
    &__checkout{
        text-align: center !important;
        margin-bottom: rem(12);
        display: block !important;  
        align-items: center !important;
        align-content: center !important;   
    }

    &__input{
        color: white;
    }
 
}