.singleCard{
    background-color: white;
    padding:rem(10);
    margin-top: rem(10);
    margin-bottom: rem(10);
    border-radius: rem(10);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(202, 202, 202, 0.715);
    &__accept{
        width: rem(20) !important;
    }

    &__selectable{
        cursor: pointer;
    }
    &__selected{
        border: 1px solid greenyellow;
    }

    &__title{
        color: $primaryGray;        
        font-size: rem(20);
    }

    &__box{
        margin-top: rem(20);
    }

    &__price{
        color: $primaryGray;
        width: auto;
        font-size: rem(13);
    }
    &__rating{
        text-align: center;
        width: 150px;
        button{
            margin-bottom: rem(10);
            font-size: 10px !important;
            padding: rem(2) !important;
        }
    }
    &__content{
        text-align: right;
        padding-right: rem(10);
        width: 90%;
        margin-left: rem(10);
        color:$primaryGray;
        font-weight: bold;
        &__description{
            color:$primaryGray;
            font-size: rem(8);
        }
    }
    img{
        width: rem(70);
    }
}