.menuDetailPage{
    padding: 0px !important;
    &__mainBox{
        margin-top: rem(10);
        padding: rem(10);
    }
    &__image{
        text-align: center;
        margin-top: rem(10);    
    }
    &__rating{
        margin: rem(10) rem(20) rem(10) rem(20);
        text-align: center;
        width: 100%;
    }
    &__box{
        position: relative;
        //background-color: $bgOrange;
        border-radius: 25px 27px 200px 200px;
        -moz-border-radius: 25px 27px 200px 200px;
        -webkit-border-radius: 25px 27px 200px 200px;
        padding: rem(0) rem(20) rem(0) rem(20) !important;
        display: block !important;
        align-items: center;

        img{
            width: rem(220);
        }
      }
      &__title{
        color: $primaryGray;
        text-transform: none !important;
        text-align: center;
        width: 100%;
        font-weight: bold;
        margin-bottom: rem(100);
        &__quantity{
            font-size: 12px;
            margin-top: rem(10);
        }
        &__totalPrice{
            font-size: 14px;
            margin-bottom: rem(20);
        }
        &__description{
            padding-left: rem(20) !important;
            padding-right: rem(20) !important;
            color:$primaryGray;
            font-size: rem(10);
        }
        &__observation{
            font-size: 12px;
        }
    }
    &__goMenu{
        margin-top: rem(20);
    }

}