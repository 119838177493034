//Clases de css comunes en la aplicacióin
.errorMessage {
  color: $secondaryRed;
}

.helperMessage {
  font-size: 0.75rem;
  color: $inputHelperText;
}

.softGrayText {
  color: $softGray;
}

.noTextDecoration {
  text-decoration: none;
}

.textAlignCenter {
  text-align: center;
}

.bgPrimaryBlue {
  background-color: $primaryBlue;
}

.marginZeroAuto {
  margin: 0 auto;
}

.primaryButton {
  color: white;
  background-color: $primaryButton;
  &:hover {
    background-color: $primaryButtonHover;
  }
}
.cardForm {
  padding: 10px;
  font-size: 12px;
}

.orangeBorder {
  border: 1px solid $primaryButton;
}

.flexAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flexAroundWidth {
  @extend .flexAround;
  width: 25rem;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputImage {
  display: none;
}
.labelInputFile {
  align-items: center;
  text-align: center;
}
.labelInputImage {
  margin: 20px;
  background-color: #e0e0e0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  border-radius: 50%;
  height: 70px;
  transition: all ease 500ms;
  box-shadow: 0px 3px 5px -1px #00000033;
  cursor: pointer;
  &:hover {
    background-color: #c3c2c2;
  }
}
.prevImageInput {
  position: relative;
  .prevImageFile {
    max-width: 100%;
    max-height: 400px;
    padding: 10px;
    border: 1px solid #000;
  }
  .closeImageButton {
    position: absolute;
    margin-left: -2rem;
    top: 0;
    background: none;
    border: none;
    color: red;
    font-size: 34px;
    z-index: 3;
    cursor: pointer;
  }
}
.error_message {
  color: $primaryRed;
}
.flexStartRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.marginLeft1 {
  margin-left: 0.75rem;
}

// This class is used by Material UI to make the label white by default when
// the input is selected (it won't be visible on white backgrounds,
// which is the most likely case in which TextField components will be used).
// Overwriting it so the label remains visible.
.css-1buyxla-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: $softGray;
}

.error_message {
  color: red;
}

.orangeTitle {
  color: $orangeTitle;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: rem(10);
  text-align: left;
}

.pull-left {
  text-align: left;
}
.pull-rght {
  text-align: right;
}

.standardColorIcon {
  color: $orangeTitle !important;
}
.redColorIcon {
  color: $colorRed !important;
}

.whiteColorIcon {
  color: $normalWhite !important;
  width: rem(18) !important;
  margin-left: rem(5);
}

a {
  color: $orangeTitle;
}

.grayTitle {
  color: $primaryGray;
  font-weight: bold;
  font-size: rem(25);

  &__description {
    font-size: rem(15);
    font-weight: 200;
  }
}

.redTitle {
  color: $colorRed;
  font-weight: bold;
  font-size: rem(25);
}

.grayTitle {
  color: $primaryGray;
  font-weight: bold;
  font-size: rem(25);
}

.graySubTitle {
  color: $primaryGray;
  font-weight: bold;
  font-size: rem(15);
}
