.camelCaseText {
  text-transform: capitalize !important;
}

.camelCaseText::first-letter {
  text-transform: lowercase !important;
}

.primaryButton {
  color: $normalWhite !important;
  background: linear-gradient(
    90deg,
    #ff8000 0%,
    #fe7a00 20%,
    #fe6c03 43%,
    #fe5306 69%,
    #fe310c 97.01%,
    #fe2d0d 100.01%
  ) !important;
  // -webkit-box-shadow: -5px 4px 6px -1px rgba(0,0,0,0.64);
  // box-shadow: -5px 4px 6px -1px rgba(0,0,0,0.64);
  //text-transform: capitalize !important;
  font-size: 11px !important;
  padding-left: rem(20) !important;
  padding-right: rem(20) !important;
  border-radius: $buttonRadius !important;
  @extend .camelCaseText;
}
.secundaryButton {
  color: $orangeTitle !important;
  background-color: $bgOrange !important;
  font-size: 11px !important;
  padding-left: rem(20) !important;
  padding-right: rem(20) !important;
  border-radius: $buttonRadius !important;
  @extend .camelCaseText;
}
.infoButton {
  color: white !important;
  background-color: $secondaryBlue !important;
  font-size: 11px !important;
  padding-left: rem(20) !important;
  padding-right: rem(20) !important;
  border-radius: $buttonRadius !important;
  @extend .camelCaseText;
}
.paymentButton {
  color: white !important;
  background-color: $primaryGreen !important;
  font-size: 11px !important;
  padding-left: rem(20) !important;
  padding-right: rem(20) !important;
  border-radius: $buttonRadius !important;
  @extend .camelCaseText;
}

.dangerButton {
  color: white !important;
  background-color: $colorRed !important;
  font-size: 11px !important;
  padding-left: rem(20) !important;
  padding-right: rem(20) !important;
  border-radius: $buttonRadius !important;
  @extend .camelCaseText;
}
.categoryDiv {
  padding: 5px;
  width: fit-content;
  block-size: fit-content;
}
.categoryButton {
  border-radius: rem(5);
  color: $orangeTitle !important;
  background-color: white !important;
  border: 1px solid $orangeTitle !important;
  font-size: 11px !important;
  text-transform: capitalize !important;
  padding: rem(5) rem(10) rem(5) rem(10) !important;
  &__active {
    color: white !important;
    background-color: $orangeTitle !important;
  }
}
.menuButton {
  color: $orangeTitle !important;
  background-color: white !important;
  border: 1px solid $orangeTitle !important;
  font-size: 11px !important;
  @extend .camelCaseText;
  border-radius: $buttonRadius !important;
  padding: rem(5) rem(10) rem(5) rem(10) !important;
  &__active {
    color: white !important;
    background-color: $orangeTitle !important;
    @extend .camelCaseText;
    border-radius: $buttonRadius !important;
  }
}
