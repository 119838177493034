.initpage{
  &__errorCamera{
    margin-top: rem(20);
  }
  &__camera{
    margin-top: rem(30);
    text-align: center;
    display: flex;
    align-content: center;
  }
}