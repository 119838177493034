.layoutProtected {
  font-family: $f-text;
  font-size: 14px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: rem(40) !important;
  &__header {
    display: flex;
    justify-content: space-around;
    padding-top: rem(20);

    &__logo {
      width: 70%;
      display: flex;
      justify-content: center;
      display: inline !important;
      img {
        width: rem(100);
      }
      &__title {
        color: $primaryGray;
        font-weight: bold;
        margin-left: rem(10);
      }
    }
  }
}
