.qtyComponent{
    display: flex;
    justify-content: center;
    align-items: center;
    button{
        margin:rem(10);
        background-color: $bgOrange;
        border-radius: 30px;
        width: rem(25);
        height: rem(25);
        border: 0px;
        color:red;
        font-size: rem(20);
    }

    &__qty{
        color:$orangeTitle;
        font-size:rem(15) ;
    }

}