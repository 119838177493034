.profilePage{
    padding: 0px !important;

    &__name{
        text-align: center;
        margin-top: rem(15);
    }
    &__line{
        width: rem(5);
        background-color:#A0A0A0;
        margin-top: rem(15);
        margin-bottom: rem(15);
    }
    &__actions{
        margin-top: rem(30);
        display: flex;
        justify-content: space-between;
        button{
            padding: rem(5) !important;
        }
    }
    &__points{
        padding: rem(0) rem(30) rem(0) rem(30);
        color: $primaryGray;
        font-weight: bold;
        text-align: center;
        margin-top: rem(15);
        display: flex;
        justify-content: space-between;
        &__orders{
            font-size: rem(40);
            line-height: rem(40);
            &__title{
                font-size: rem(20);
            }
        }
        &__points{
            font-size: rem(40);
            line-height: rem(40);
            &__title{
                font-size: rem(20);
            }
        }        
    }
    &__mainBox{
        padding: rem(0) rem(20) rem(0) rem(20);
        text-align: center;
    }
    &__resumeBox{
        margin-bottom:rem(20);
        padding: rem(20) !important;
        border-radius: rem(20);
        &__line{
            width: 100%;
            border-bottom: 1px solid $primaryGray;
            margin-top: rem(15);
            margin-bottom: rem(15);
        }
        &__name{
            width: 100%;           
        }
        &__number{
            width: 100%;           
        }
        &__date{
            &__content{
                width: 48%;
            }
            width: 100%;   
            display: flex;
            justify-content: space-between;        
        }
        &__total{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }    
    &__box{
        position: relative;
        //background-color: $bgOrange;
        padding: rem(20) rem(20) rem(0) rem(20) !important;
        display: block !important;
        align-items: center;       
        margin-bottom: rem(20);
        text-align: center !important;
      }
   
    &__continue{
        text-align: center;
        margin-bottom: rem(12);
    }
    
    &__checkout{
        text-align: center;
        margin-bottom: rem(12);
    }

    &__input{
        color: white;
    }
 
}