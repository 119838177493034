.menuPage{
    padding: 0px !important;
    &__mainBox{
        margin-top: rem(10);
        padding: rem(10);
    }
    &__list{
    width: 100%;
    margin-top: rem(10);
    }
    &__box{
        //background-color: $bgOrange;
        -webkit-border-top-left-radius: 15px;
        -webkit-border-top-right-radius: 15px;
        -moz-border-radius-topleft: 15px;
        -moz-border-radius-topright: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: rem(20) !important;
      }
}