.register {
  padding-left: 0px !important;
  padding-right: 0px !important;
  &__form{
      background-color: $normalWhite;
      margin-top: rem(20);
  }
  &__box{
    background-color: $normalWhite;
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: rem(20);
    padding-bottom: rem(20);
  }

}
