.stepperComponent{
   &__step{
    font-family: $f-text !important;
   }

   #RFS-StepContainer{
/*       top: calc((2em - 4px) / 2);
      left: calc((-50% + 2em) - 8px);
      right: calc((50% + 2em) - 8px);
      position: absolute; */
      #RFS-StepMain{
         display: flex;
         align-items: center;
         flex-direction: column;
      }
      #RFS-ConnectorContainer{
         top: calc((2em - 4px) / 2);
         left: calc((-50% + 2em) - 8px);
         right: calc((50% + 2em) - 8px);
         position: absolute;
      }
      #RFS-Connector{
         display: block;
         border-color: #F0532D;
         border-top-style: solid;
         border-top-width: 4px;
      }
   }
}
