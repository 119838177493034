// Spacing
$spacing-desktop: 16 24 32 48 80 128;
$spacing-mobile: 8 16 24 32 48 64;
$spacing-names: 'xs' 's' 'm' 'l' 'xl' 'xxl';

@each $size in $spacing-mobile {
  $i: index($spacing-mobile, $size);

  .mb-#{nth($spacing-names, $i)} {
    margin-bottom: #{$size}px;
  }

  .mt-#{nth($spacing-names, $i)} {
    margin-top: #{$size}px;
  }
}

@each $size in $spacing-desktop {
  $i: index($spacing-desktop, $size);

  @include breakpoint(tablet) {
    .mb-#{nth($spacing-names, $i)} {
      margin-bottom: #{$size}px;
    }

    .mt-#{nth($spacing-names, $i)} {
      margin-top: #{$size}px;
    }
  }
}
