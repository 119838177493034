.cartPage{
    padding: 0px !important;
    &__itemList{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: rem(20);

        img{
            width: rem(100);
        }
    }

    &__mainBox{
        padding: rem(0) rem(20) rem(0) rem(20);
    }
    &__box{
        position: relative;
        //background-color: $bgOrange;
        padding: rem(0) rem(20) rem(0) rem(20) !important;
        display: block !important;
        align-items: center;
      }
    &__content{
        width: 100%;
        margin-left: rem(10);
        color:$primaryGray;
        font-weight: bold;
        font-size: rem(12);
        &__description{
            color:$primaryGray;
            font-size: rem(10);
            font-weight: 200;
        }
        &__complements{
            color:$primaryGray;
            font-size: rem(10);
            font-weight: 200;
        }
        &__quantity{
            color:$primaryGray;
            font-size: rem(10);
            font-weight: 200;
        }
        &__price{
            color:$colorRed;
            font-size: rem(10);
            font-weight: 200;
        }
    }
    &__continue{
        text-align: center;
        margin-bottom: rem(12);
    }
    
    &__checkout{
        text-align: center;
        margin-bottom: rem(12);
    }
    
    &__actions{
        padding: rem(10);
        text-align: center;
        button{
            padding: rem(5) !important;
            margin-bottom: rem(10);
        }
    }
}